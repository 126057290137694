import { fabric } from "@utils/fabric";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { MIN_PROPERTY_HEIGHT } from "@utils/constant";
import NumberInput from "./shared/NumberInput";

function getScaledHeight(selectedObject: fabric.Object | null) {
  return Math.round(
    (selectedObject?.height || MIN_PROPERTY_HEIGHT) *
      (selectedObject?.scaleY || 1)
  );
}

function Height() {
  const {
    canvas,
    selectedObject,
    coreHandler,
    isWidthHeightProportionalScaled,
  } = useContext(AppContext);
  const [height, setHeight] = useState(getScaledHeight(selectedObject));

  // triggered by clicking layers
  useEffect(() => {
    setHeight(getScaledHeight(selectedObject));
  }, [selectedObject]);

  // triggered by fabric controls
  useEffect(() => {
    function onModified(event: any) {
      const newHeight = getScaledHeight(selectedObject);
      setHeight(newHeight);
    }

    coreHandler?.eventHandler.on("object:modified", onModified);
    return () => {
      coreHandler?.eventHandler.off("object:modified", onModified);
    };
  }, [coreHandler, selectedObject]);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = Math.max(
      MIN_PROPERTY_HEIGHT,
      parseInt(event.target.value, 10)
    );

    if (isWidthHeightProportionalScaled) {
      selectedObject?.scaleToHeight(value);
    } else {
      selectedObject?.set(
        "scaleY",
        value / (selectedObject?.height || MIN_PROPERTY_HEIGHT)
      );
    }

    setHeight(value);
    coreHandler?.eventHandler.modified({ target: selectedObject });
    canvas?.renderAll();
  }

  return (
    <NumberInput
      onChange={onChange}
      value={height}
      min={MIN_PROPERTY_HEIGHT}
      className="h-8 w-full rounded"
    />
  );
}

export default Height;
