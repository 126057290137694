import { fabric } from "@utils/fabric";
import nanoid from "@utils/nanoid";

// handlers
import BaseHandler from "./BaseHandler";
import FontHandler from "./FontHandler";
import EventHandler from "./EventHandler";
import CanvasHandler from "./CanvasHandler";
import ShortcutHandler from "./ShortcutHandler";
import ServerAPIHandler from "./ServerAPIHandler";
import WorkspaceHandler from "./WorkspaceHandler";
import TransactionHandler from "./TransactionHandler";

class CoreHandler extends BaseHandler {
  public canvas: fabric.Canvas;

  public handlers: BaseHandler[];

  public serverAPIHandler: ServerAPIHandler;

  public shortCutHandler: ShortcutHandler;

  public eventHandler: EventHandler;

  public canvasHandler: CanvasHandler;

  public transactionHandler: TransactionHandler;

  public workspaceHandler: WorkspaceHandler;

  public fontHandler: FontHandler;

  public keyEvent?: KeyboardEvent;

  public hasUnsavedChanges: boolean;

  public isInitialized: boolean;

  public isDesignLoaded: boolean;

  public isCopiedObject?: fabric.Object;

  constructor(canvas: fabric.Canvas) {
    super();

    this.canvas = canvas;
    this.hasUnsavedChanges = false;
    this.isDesignLoaded = false;
    this.isCopiedObject = undefined;
    this.isInitialized = false;

    this.fontHandler = new FontHandler(this);
    this.eventHandler = new EventHandler(this);
    this.canvasHandler = new CanvasHandler(this);
    this.shortCutHandler = new ShortcutHandler(this);
    this.serverAPIHandler = new ServerAPIHandler(this);
    this.workspaceHandler = new WorkspaceHandler(this);
    this.transactionHandler = new TransactionHandler(this);

    this.handlers = [
      this.fontHandler,
      this.eventHandler,
      this.canvasHandler,
      this.shortCutHandler,
      this.serverAPIHandler,
      this.workspaceHandler,
      this.transactionHandler,
    ];

    this.initialize();
  }

  initialize(): void {
    if (!this.isInitialized) {
      this.isInitialized = true;
      this.handlers.forEach((handler) => {
        handler.initialize();
      });
    }
  }

  destroy(): void {
    this.handlers.forEach((handler) => {
      handler.destroy();
    });
    this.isInitialized = false;
  }

  add(object: fabric.Object, centered = true): fabric.Object {
    const type = object.type;
    object.name = `${type}-${nanoid()}`;

    this.canvas.add(object);
    if (centered) {
      this.canvas.centerObject(object);
      object.setCoords();
    }
    this.canvas.setActiveObject(object);
    this.canvas.requestRenderAll();
    this.transactionHandler.save("add");

    return object;
  }

  async clone(object: fabric.Object): Promise<fabric.Object> {
    return new Promise((resolve) => {
      object.clone((cloned) => {
        cloned.set("top", (object.top ?? 0) + 10);
        cloned.set("left", (object.left ?? 0) + 10);
        cloned.name = `${object.type}-${nanoid()}`;
        resolve(cloned);
      });
    });
  }

  clear() {
    this.canvas.discardActiveObject();
    this.canvas.getObjects().forEach((eachObject) => {
      this.canvas.remove(eachObject);
    });
    this.canvas.renderAll();
  }

  remove() {
    const activeObject = this.canvas?.getActiveObject();
    if (activeObject) {
      this.transactionHandler.save("remove");
      this.canvas?.remove(activeObject);
    }
  }

  selectAll() {
    const firstObject = this.canvas?.getObjects()[0];
    if (firstObject) {
      this.canvas?.setActiveObject(firstObject);
      this.canvas?.renderAll();
    }
  }

  bringToFront() {
    const activeObject = this.canvas?.getActiveObject();
    if (activeObject) {
      this.canvas?.bringToFront(activeObject);
      this.transactionHandler.save("reorder");
      this.eventHandler.emit("canvas:reorder");
    }
  }

  bringForward() {
    const activeObject = this.canvas?.getActiveObject();
    if (activeObject) {
      this.canvas?.bringForward(activeObject);
      this.transactionHandler.save("reorder");
      this.eventHandler.emit("canvas:reorder");
    }
  }

  sendBackward() {
    const activeObject = this.canvas?.getActiveObject();
    if (activeObject) {
      this.canvas?.sendBackwards(activeObject);
      this.transactionHandler.save("reorder");
      this.eventHandler.emit("canvas:reorder");
    }
  }

  sendToBack() {
    const activeObject = this.canvas?.getActiveObject();
    if (activeObject) {
      this.canvas?.sendToBack(activeObject);
      this.transactionHandler.save("reorder");
      this.eventHandler.emit("canvas:reorder");
    }
  }

  alignToLeftEdge() {
    const activeObject = this.canvas?.getActiveObject();
    if (activeObject) {
      const boundingRect = activeObject.getBoundingRect();
      const objectCenter = activeObject.getCenterPoint();
      activeObject.setPositionByOrigin(
        new fabric.Point(boundingRect.width / 2, objectCenter.y),
        "center",
        "center"
      );
      this.eventHandler.modified({} as any);
      this.canvas.requestRenderAll();
    }
  }

  alignToCenterHorizontal() {
    const activeObject = this.canvas?.getActiveObject();
    if (activeObject) {
      const canvasWidth = this.canvas.getWidth();
      const objectCenter = activeObject.getCenterPoint();
      activeObject.setPositionByOrigin(
        new fabric.Point(canvasWidth / 2, objectCenter.y),
        "center",
        "center"
      );
      this.eventHandler.modified({} as any);
      this.canvas.requestRenderAll();
    }
  }

  alignToRightEdge() {
    const activeObject = this.canvas?.getActiveObject();
    if (activeObject) {
      const canvasWidth = this.canvas.getWidth();
      const boundingRect = activeObject.getBoundingRect();
      const objectCenter = activeObject.getCenterPoint();
      activeObject.setPositionByOrigin(
        new fabric.Point(canvasWidth - boundingRect.width / 2, objectCenter.y),
        "center",
        "center"
      );
      this.eventHandler.modified({} as any);
      this.canvas.requestRenderAll();
    }
  }

  alignToTopEdge() {
    const activeObject = this.canvas?.getActiveObject();
    if (activeObject) {
      const boundingRect = activeObject.getBoundingRect();
      const objectCenter = activeObject.getCenterPoint();
      activeObject.setPositionByOrigin(
        new fabric.Point(objectCenter.x, boundingRect.height / 2),
        "center",
        "center"
      );
      this.eventHandler.modified({} as any);
      this.canvas.requestRenderAll();
    }
  }

  alignToCenterVertical() {
    const activeObject = this.canvas?.getActiveObject();
    if (activeObject) {
      const canvasHeight = this.canvas.getHeight();
      const objectCenter = activeObject.getCenterPoint();
      activeObject.setPositionByOrigin(
        new fabric.Point(objectCenter.x, canvasHeight / 2),
        "center",
        "center"
      );
      this.eventHandler.modified({} as any);
      this.canvas.requestRenderAll();
    }
  }

  alignToBottomEdge() {
    const activeObject = this.canvas?.getActiveObject();
    if (activeObject) {
      const canvasHeight = this.canvas.getHeight();
      const boundingRect = activeObject.getBoundingRect();
      const objectCenter = activeObject.getCenterPoint();
      activeObject.setPositionByOrigin(
        new fabric.Point(
          objectCenter.x,
          canvasHeight - boundingRect.height / 2
        ),
        "center",
        "center"
      );
      this.eventHandler.modified({} as any);
      this.canvas.requestRenderAll();
    }
  }

  markIsDesignLoaded(isLoaded = false) {
    this.isDesignLoaded = isLoaded;
    this.eventHandler.emit("design:loaded");
  }
}

export default CoreHandler;
