import React, { useContext, useEffect, useState } from "react";
import { fabric } from "@utils/fabric";
import { AppContext } from "../../context/AppContext";

function LineHeight() {
  const { canvas, selectedObject } = useContext(AppContext);
  const [lineHeight, setLineHeight] = useState(
    (selectedObject as fabric.Textbox)?.get("lineHeight")
  );

  // triggered by clicking layers
  useEffect(() => {
    setLineHeight((selectedObject as fabric.Textbox)?.get("lineHeight"));
  }, [selectedObject]);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = parseFloat(event.target.value);
    setLineHeight(value);
    (selectedObject as fabric.Textbox)?.set({ lineHeight: value });
    canvas?.renderAll();
  }

  return (
    <div>
      <input
        type="number"
        onChange={onChange}
        value={lineHeight}
        step={0.1}
        className="h-8 w-full rounded"
      />
    </div>
  );
}

export default LineHeight;
