import { toast } from "react-toastify";
import { fabric, getFontFamiliesFromFabricObjects } from "@utils/fabric";
import { deepEqual } from "fast-equals";
import { useDebouncedCallback } from "use-debounce";
import { useEffect, useContext, useState, useCallback } from "react";
import { useParams, Navigate, useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { updateTemplate } from "@apis/template";
import { AppContext } from "../context/AppContext";
import useBeforeUnload from "@hooks/useBeforeUnload";
import { usePrompt } from "@hooks/reactDOM";
import Canvas from "@components/Canvas";
import Header from "@components/Header";
import Layers from "@components/Layers";
import Properties from "@components/Properties";
import Toolbars from "@components/Toolbars";
import Workspace from "@components/Workspace";
import TemplateSettingsModal from "@components/Modals/TemplateSettingsModal";
import ImagePickerModal from "@components/Modals/ImagePickerModal/ImagePickerModal";
import { Template } from "@base/type";
import { ROUTE_PREVIEW } from "@constants/routes";
import { DEFAULT_CANVAS_BACKGROUND_COLOR } from "@utils/constant";

function Editor() {
  const navigate = useNavigate();
  const { templateId } = useParams();
  const {
    canvas,
    setTemplates,
    templates,
    coreHandler,
    isLayerOpened,
    setIsLayerOpened,
  } = useContext(AppContext);
  const [isSaving, setIsSaving] = useState(false);
  const [isTemplateSettingsModalShown, setIsTemplateSettingsModalShown] =
    useState(false);
  const [isAddImageModalShown, setIsAddImageModalShown] = useState(false);
  const [template, setTemplate] = useState(
    templates.find((template) => template.id === templateId)!
  );
  const [baseTemplateData, setBaseTemplateData] = useState(template?.data);
  const [isDesignDirty, setIsDesignDirty] = useState(0);

  const onImagePicked = (image: string | File | undefined) => {
    if (image) {
      let newImage: fabric.Image;

      if (typeof image === "string") {
        fabric.Image.fromURL(
          image,
          function (loadedImage) {
            const isFailed = !!arguments[1];
            if (isFailed) {
              toast.error(
                "Failed to load image, please make sure your image is with CORS header"
              );
              return;
            }

            newImage = loadedImage;
            newImage.scale(0.5);
            coreHandler?.add(newImage);
          },
          {
            crossOrigin: "anonymous",
          }
        );
      } else {
        const fileReader = new FileReader();

        fileReader.onload = (event) => {
          const dataURL = event.target?.result as string;

          // try to do the fast preview first
          fabric.Image.fromURL(
            dataURL,
            (loadedImage) => {
              newImage = loadedImage;
              newImage.scale(0.5);
              coreHandler?.add(newImage);
            },
            { crossOrigin: "anonymous" }
          );

          // access the image from our own S3
          coreHandler?.serverAPIHandler
            .uploadImageToS3(image)
            .then((url) => {
              newImage?.setSrc(
                url,
                () => {
                  coreHandler?.canvas?.requestRenderAll();
                },
                {
                  crossOrigin: "anonymous",
                }
              );
            })
            .catch((error) => {
              toast.error(error);

              // TODO: this may mess up transaction
              coreHandler?.canvas?.remove(newImage);
            });
        };

        fileReader.readAsDataURL(image);
      }
    }
  };

  const onSaveTemplate = useCallback(
    ({ forceSave } = {}) => {
      if (!forceSave && (!canvas || isSaving || !isDesignDirty)) {
        return;
      }

      setIsSaving(true);

      // TODO - if it failed to save, should we restore it to previous state?
      const newCanvasData = canvas.toObject();
      newCanvasData.background = DEFAULT_CANVAS_BACKGROUND_COLOR;

      const newTemplate = {
        ...template,
        data: newCanvasData,
      };

      updateTemplate(newTemplate)
        .then((result) => {
          const { data: updatedTemplate } = result;
          setTemplates((prevTemplates) => {
            return prevTemplates.map((eachTemplate) => {
              if (eachTemplate.id === updatedTemplate.id) {
                return updatedTemplate;
              }
              return eachTemplate;
            });
          });
          setBaseTemplateData(newCanvasData);
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          const canvasJSON = canvas.toJSON();
          if (!deepEqual(canvasJSON.objects, newCanvasData.objects)) {
            onSaveTemplate({
              forceSave: true,
            });
          } else {
            setIsDesignDirty(0);
            setIsSaving(false);
          }
        });
    },
    [canvas, isDesignDirty, isSaving, setTemplates, template]
  );

  const onDebouncedSaveTemplate = useDebouncedCallback(onSaveTemplate, 3000);

  useEffect(() => {
    if (coreHandler?.isDesignLoaded) {
      return;
    }

    const fontFamilies = getFontFamiliesFromFabricObjects(
      template?.data.objects
    );

    // no matter what, we would always render the canvas even if the font is failed to load
    coreHandler?.fontHandler
      .loadFont(fontFamilies)
      .catch((error) => {
        console.log(error);
        toast.error("Failed to load fonts, please try again later.");
      })
      .finally(() => {
        canvas?.loadFromJSON(template?.data, () => {
          canvas.renderAll();
          coreHandler?.markIsDesignLoaded(true);
        });
      });
  }, [canvas, coreHandler, template?.data]);

  useEffect(() => {
    const foundTemplate = templates.find(
      (template) => template.id === templateId
    )!;
    setTemplate(foundTemplate);
    setBaseTemplateData(foundTemplate?.data);
  }, [templateId, templates]);

  useEffect(() => {
    const onDesignDirty = () => {
      const canvasJSON = canvas.toJSON();
      const isDesignChanged = !deepEqual(
        canvasJSON.objects,
        baseTemplateData.objects
      );
      if (isDesignChanged) {
        setIsDesignDirty(1);
      }
    };

    coreHandler?.eventHandler.on("object:added", onDesignDirty);
    coreHandler?.eventHandler.on("object:removed", onDesignDirty);
    coreHandler?.eventHandler.on("object:modified", onDesignDirty);
    coreHandler?.eventHandler.on("property:changed", onDesignDirty);
    coreHandler?.eventHandler.on("file:save", onSaveTemplate);

    return () => {
      coreHandler?.eventHandler.off("object:added", onDesignDirty);
      coreHandler?.eventHandler.off("object:removed", onDesignDirty);
      coreHandler?.eventHandler.off("object:modified", onDesignDirty);
      coreHandler?.eventHandler.off("property:changed", onDesignDirty);
      coreHandler?.eventHandler.off("file:save", onSaveTemplate);
    };
  }, [baseTemplateData, canvas, coreHandler?.eventHandler, onSaveTemplate]);

  useEffect(() => {
    if (isDesignDirty) {
      onDebouncedSaveTemplate();
    }
  }, [isDesignDirty, onDebouncedSaveTemplate]);

  useBeforeUnload(() => {
    return !!isDesignDirty;
  });

  usePrompt(
    "You have unsaved changes. Are you sure you want to leave?",
    !!isDesignDirty
  );

  if (!template) {
    // TODO - add a error page for this one
    return <Navigate to="/" replace></Navigate>;
  }

  return (
    <div className="h-full">
      <ReactTooltip />
      <Header />
      <div className="h-[calc(100%-73px)]">
        <div className="flex h-full">
          <div className={`${isLayerOpened ? "w-1/12" : ""}`}>
            <Layers
              isLayerOpened={isLayerOpened}
              onLayerToggle={() => {
                setIsLayerOpened(!isLayerOpened);
              }}
            />
          </div>
          <div className={`${isLayerOpened ? "w-11/12" : "w-full"}`}>
            <div>
              <Toolbars
                isSaving={isSaving}
                toShowSaveTemplateButton={!!isDesignDirty}
                onSaveTemplate={onSaveTemplate}
                onRenderClick={() => {
                  navigate(`${ROUTE_PREVIEW}/${template.id}`);
                }}
                onAddImageClick={() => {
                  setIsAddImageModalShown(true);
                }}
                onTemplateSettingsClick={() => {
                  setIsTemplateSettingsModalShown(true);
                }}
                onShortcutClick={() => {}}
              />
            </div>
            <div className="flex h-[calc(100%-48px)]">
              <div className="w-4/5">
                <Workspace template={template}>
                  <Canvas template={template} />
                </Workspace>
              </div>
              <div className="w-1/5">
                <Properties />
              </div>
            </div>
          </div>
        </div>
      </div>
      <TemplateSettingsModal
        header="Template Settings"
        open={isTemplateSettingsModalShown}
        templateInfo={{
          width: template.width,
          height: template.height,
          title: template.title,
          id: template.id,
          public: template.public,
        }}
        onCancel={() => {
          setIsTemplateSettingsModalShown(false);
        }}
        onSubmit={(newTemplateInfo) => {
          setIsTemplateSettingsModalShown(false);

          const newTemplate = {
            id: template.id,
            created_by: template.created_by,
            ...newTemplateInfo,
          };

          toast.promise(updateTemplate(newTemplate), {
            pending: "Saving Template ...",
            success: {
              render({
                data: { data: updatedTemplate },
              }: {
                data: { data: Template };
              }) {
                setTemplates((prevTemplates) => {
                  return prevTemplates.map((eachTemplate) => {
                    if (eachTemplate.id === updatedTemplate.id) {
                      return updatedTemplate;
                    }
                    return eachTemplate;
                  });
                });

                return "Template saved!";
              },
            },
            error: {
              render({ data }) {
                return (data as Error).message;
              },
            },
          });
        }}
      ></TemplateSettingsModal>
      <ImagePickerModal
        open={isAddImageModalShown}
        onCancel={() => {
          setIsAddImageModalShown(false);
        }}
        onSubmit={(image) => {
          onImagePicked(image);
          setIsAddImageModalShown(false);
        }}
      />
    </div>
  );
}

export default Editor;
