import { fabric } from "@eragonj/copicake-fabric";

export const getFontFamiliesFromFabricObjects = (
  objects: fabric.Object[] = []
) => {
  const textboxes = objects.filter(
    (object) => object.type === "textbox"
  ) as fabric.Textbox[];

  return textboxes.map((textbox: { fontFamily: any }) => {
    return textbox.fontFamily;
  });
};

export { fabric };
