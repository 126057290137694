import { fabric } from "@utils/fabric";
import { createDefaultObject, debounce } from "@utils/utils";
import BaseHandler from "./BaseHandler";
import CoreHandler from "./CoreHandler";

class EventHandler extends BaseHandler {
  public coreHandler: CoreHandler;

  private isListening: boolean;

  constructor(coreHandler: CoreHandler) {
    super();

    this.coreHandler = coreHandler;
    this.isListening = false;

    this.onKeydown = this.onKeydown.bind(this);

    // object
    this.added = this.added.bind(this);
    this.removed = this.removed.bind(this);
    this.modified = this.modified.bind(this);
    this.rotating = this.rotating.bind(this);
    this.scaling = this.scaling.bind(this);
    this.scaled = debounce(this.scaled.bind(this));
    this.moving = this.moving.bind(this);

    // selection
    this.selectionCleared = this.selectionCleared.bind(this);
    this.selectionCreated = this.selectionCreated.bind(this);
    this.selectionUpdated = this.selectionUpdated.bind(this);

    // mouse
    this.mousedown = this.mousedown.bind(this);
    this.mousemove = this.mousemove.bind(this);
    this.mouseover = this.mouseover.bind(this);
    this.mouseout = this.mouseout.bind(this);
    this.mouseup = this.mouseup.bind(this);

    // text
    this.textEditingEntered = this.textEditingEntered.bind(this);
    this.textEditingExited = this.textEditingExited.bind(this);

    // customized
    this.propertyChanged = this.propertyChanged.bind(this);
  }

  initialize(): void {
    if (!this.isListening) {
      this.isListening = true;
      window.addEventListener("keydown", this.onKeydown);

      // object
      this.coreHandler.canvas.on("object:added", this.added);
      this.coreHandler.canvas.on("object:removed", this.removed);
      this.coreHandler.canvas.on("object:modified", this.modified);
      this.coreHandler.canvas.on("object:rotating", this.rotating);
      this.coreHandler.canvas.on("object:scaling", this.scaling);
      this.coreHandler.canvas.on("object:moving", this.moving);

      // selection
      this.coreHandler.canvas.on("selection:cleared", this.selectionCleared);
      this.coreHandler.canvas.on("selection:created", this.selectionCreated);
      this.coreHandler.canvas.on("selection:updated", this.selectionUpdated);

      // mouse
      this.coreHandler.canvas.on("mouse:down", this.mousedown);
      this.coreHandler.canvas.on("mouse:move", this.mousemove);
      this.coreHandler.canvas.on("mouse:over", this.mouseover);
      this.coreHandler.canvas.on("mouse:out", this.mouseout);
      this.coreHandler.canvas.on("mouse:up", this.mouseup);

      // text
      this.coreHandler.canvas.on(
        "text:editing:entered",
        this.textEditingEntered
      );
      this.coreHandler.canvas.on("text:editing:exited", this.textEditingExited);

      // customized
      this.coreHandler.canvas.on("property:changed", this.propertyChanged);
    }
  }

  destroy(): void {
    if (this.isListening) {
      window.removeEventListener("keydown", this.onKeydown);

      // object
      this.coreHandler.canvas.off("object:added", this.added);
      this.coreHandler.canvas.off("object:removed", this.removed);
      this.coreHandler.canvas.off("object:modified", this.modified);
      this.coreHandler.canvas.off("object:rotating", this.rotating);
      this.coreHandler.canvas.off("object:rotating", this.rotating);
      this.coreHandler.canvas.off("object:scaling", this.scaling);
      this.coreHandler.canvas.off("object:moving", this.moving);

      // selection
      this.coreHandler.canvas.off("selection:cleared", this.selectionCleared);
      this.coreHandler.canvas.off("selection:created", this.selectionCreated);
      this.coreHandler.canvas.off("selection:updated", this.selectionUpdated);

      // mouse
      this.coreHandler.canvas.off("mouse:down", this.mousedown);
      this.coreHandler.canvas.off("mouse:move", this.mousemove);
      this.coreHandler.canvas.off("mouse:over", this.mouseover);
      this.coreHandler.canvas.off("mouse:out", this.mouseout);
      this.coreHandler.canvas.off("mouse:up", this.mouseup);

      // text
      this.coreHandler.canvas.off(
        "text:editing:entered",
        this.textEditingEntered
      );
      this.coreHandler.canvas.off(
        "text:editing:exited",
        this.textEditingExited
      );

      // customized
      this.coreHandler.canvas.off("property:changed", this.propertyChanged);

      this.isListening = false;
    }
  }

  isAnyInputFocused(): boolean {
    return document.activeElement?.tagName === "INPUT";
  }

  blurAnyInput(): void {
    (document.activeElement as HTMLElement).blur();
  }

  async onKeydown(event: KeyboardEvent) {
    if (this.isAnyInputFocused()) {
      if (
        this.coreHandler.shortCutHandler.isEnter(event) ||
        this.coreHandler.shortCutHandler.isEscape(event)
      ) {
        this.blurAnyInput();
      }
      return;
    }

    const object = this.coreHandler.canvas.getActiveObject();
    const isEditing =
      object &&
      object.type === "textbox" &&
      (object as fabric.Textbox).isEditing;

    this.coreHandler.keyEvent = event;

    if (this.coreHandler.shortCutHandler.isSelectAll(event)) {
      event.preventDefault();

      const firstObject = this.coreHandler.canvas?.getObjects()[0];
      if (firstObject) {
        this.coreHandler.canvas?.setActiveObject(firstObject);
        this.coreHandler.canvas?.renderAll();
      }
      return;
    }

    if (this.coreHandler.shortCutHandler.isReload(event)) {
      return;
    }

    if (!isEditing) {
      if (this.coreHandler.shortCutHandler.isEscape(event)) {
        this.coreHandler.canvas?.discardActiveObject();
        this.coreHandler.canvas?.renderAll();
        return;
      }

      if (this.coreHandler.shortCutHandler.isBackspace(event)) {
        this.coreHandler.remove();
        return;
      }

      if (this.coreHandler.shortCutHandler.isCopy(event)) {
        const activeObject = this.coreHandler.canvas?.getActiveObject();
        if (activeObject) {
          this.coreHandler.isCopiedObject = await this.coreHandler.clone(
            activeObject
          );
        }
        return;
      }

      if (this.coreHandler.shortCutHandler.isPaste(event)) {
        const copiedObject = this.coreHandler.isCopiedObject;
        if (copiedObject) {
          this.coreHandler.canvas.add(copiedObject);
          this.coreHandler.canvas?.setActiveObject(copiedObject);
        }
        return;
      }

      if (this.coreHandler.shortCutHandler.isRedo(event)) {
        this.coreHandler.transactionHandler.redo();
        event.preventDefault();
        return;
      }

      if (this.coreHandler.shortCutHandler.isUndo(event)) {
        this.coreHandler.transactionHandler.undo();
        event.preventDefault();
        return;
      }

      if (this.coreHandler.shortCutHandler.isC(event)) {
        const object = createDefaultObject("circle");
        this.coreHandler.add(object);
        return;
      }

      if (this.coreHandler.shortCutHandler.isR(event)) {
        const object = createDefaultObject("rect");
        this.coreHandler.add(object);
        return;
      }

      if (this.coreHandler.shortCutHandler.isA(event)) {
        const object = createDefaultObject("triangle");
        this.coreHandler.add(object);
        return;
      }

      if (this.coreHandler.shortCutHandler.isT(event)) {
        const object = createDefaultObject("text");
        this.coreHandler.add(object);
        return;
      }

      if (this.coreHandler.shortCutHandler.isP(event)) {
        this.coreHandler.workspaceHandler.setMode("pan");
        return;
      }

      if (this.coreHandler.shortCutHandler.isV(event)) {
        this.coreHandler.workspaceHandler.setMode("view");
        return;
      }

      if (this.coreHandler.shortCutHandler.isZoomIn(event)) {
        this.coreHandler.workspaceHandler.zoomIn();
        return;
      }

      if (this.coreHandler.shortCutHandler.isZoomOut(event)) {
        this.coreHandler.workspaceHandler.zoomOut();
        return;
      }

      if (this.coreHandler.shortCutHandler.isZoomAuto(event)) {
        this.coreHandler.workspaceHandler.zoomAuto();
        return;
      }

      if (this.coreHandler.shortCutHandler.isBringForward(event)) {
        this.coreHandler.bringForward();
        return;
      }

      if (this.coreHandler.shortCutHandler.isSendBackward(event)) {
        this.coreHandler.sendBackward();
        return;
      }

      if (this.coreHandler.shortCutHandler.isSave(event)) {
        event.preventDefault();
        this.emit("file:save");
        return;
      }
    }
  }

  // object
  added(event: fabric.IEvent): void {
    if (!this.coreHandler.isDesignLoaded) {
      return;
    }
    this.emit("object:added", event);
  }
  removed(event: fabric.IEvent): void {
    this.emit("object:removed", event);
  }
  modified(event: fabric.IEvent): void {
    this.emit("object:modified", event);

    // TODO - add this back later
    // const { target } = event;
    // if (target.type === "textbox") {
    //   resetTextboxMaxLines(target);
    // }
    this.coreHandler.transactionHandler.save("modified");
  }
  rotating(event: fabric.IEvent): void {}
  scaling(event: fabric.IEvent): void {
    // debounced function
    this.scaled(event);
  }
  scaled(event: fabric.IEvent): void {
    this.emit("object:scaled", event);
  }
  moving(event: fabric.IEvent): void {}

  // selection
  selectionCleared(event: fabric.IEvent): void {
    this.emit("selection:cleared", event);
  }
  selectionCreated(event: fabric.IEvent): void {
    this.emit("selection:created", event);
  }
  selectionUpdated(event: fabric.IEvent): void {
    this.emit("selection:updated", event);
  }

  // mouse
  mousedown(event: fabric.IEvent): void {
    this.coreHandler.canvasHandler.mousedown(event);
  }

  mousemove(event: fabric.IEvent): void {}
  mouseover(event: fabric.IEvent): void {
    this.coreHandler.canvasHandler.mouseover(event);
  }

  mouseout(event: fabric.IEvent): void {
    this.coreHandler.canvasHandler.mouseout(event);
  }

  mouseup(event: fabric.IEvent): void {}

  textEditingEntered(event: fabric.IEvent): void {}

  textEditingExited(event: fabric.IEvent): void {
    // TODO - add this back later
    // const textbox = event.target as fabric.Textbox;
    // resetTextboxMaxLines(textbox);
  }

  propertyChanged(): void {
    this.emit("property:changed");
  }
}

export default EventHandler;
