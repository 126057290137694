import { fabric, textbox, utils } from "@eragonj/copicake-fabric";
import { ObjectChange } from "@eragonj/copicake-fabric/dist/types/utils";
import { saveAs } from "file-saver";
import Copicake from "@copicake/copicake-js";
import Editor from "@monaco-editor/react";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Template } from "../type";
import { getWaterMarkText, DOCS_URL } from "@utils/constant";
import Header from "@components/Header";
import Loader from "@components/shared/Loader";

function Preview() {
  const canvasId = `canvas-preview`;
  const { user, templates } = useContext(AppContext);
  const [canvas, setCanvas] = useState<fabric.Canvas>();
  const [isPreviewInitialized, setIsPreviewInitialized] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [apiKey] = useState(user?.api_key || "");
  const [templateId, setTemplateId] = useState("");
  const [currentTemplate, setCurrentTemplate] = useState<Template>();
  const [imageUrl, setImageUrl] = useState("");
  const [changesInString, setChangesInString] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  // setup template data
  useEffect(() => {
    const splits = location.pathname.split("/");
    setTemplateId(splits[splits.length - 1]);

    const foundTemplate = templates.find((template) => {
      return template.id === templateId;
    });

    if (foundTemplate) {
      setCurrentTemplate(foundTemplate);

      try {
        // TODO - create for the first time and just go into preview mode, the data is jsonified string
        // and this would make this function fail, but after reload, it's in JSON object.
        // Need to double check and see what's going on here
        const templateJSON = foundTemplate.data;
        const changes = getChangesFromData(templateJSON);
        setChangesInString(JSON.stringify(changes, null, 2));
      } catch (error) {
        console.error(error);
      }
    }
  }, [location, templateId, templates]);

  useEffect(() => {
    if (!isPreviewInitialized && currentTemplate) {
      const canvas = new fabric.Canvas(canvasId, {
        width: currentTemplate.width,
        height: currentTemplate.height,
      });

      try {
        const [isValid, changes] = areChangesValid(changesInString);
        if (isValid) {
          doPreview(currentTemplate, changes, canvas);
        }
      } catch (error) {
        console.error((error as Error).message);
      }

      setCanvas(canvas);
      setIsPreviewInitialized(true);
    }
  }, [canvasId, currentTemplate, changesInString, isPreviewInitialized]);

  function onEditorChange(value: any, event: any) {
    setChangesInString(value);
  }

  function getChangesFromData(userData: Record<string, any>): any[] {
    const objects = userData.objects as any[];
    const supportedObjects = objects.filter((object) => {
      return (
        object.type === "textbox" ||
        object.type === "image" ||
        object.type === "qrcode" ||
        object.type === "circle" ||
        object.type === "triangle" ||
        object.type === "rect"
      );
    });

    return supportedObjects.map((object) => {
      const change: Record<string, unknown> = {};
      change.name = object.name;

      if (object.type === "textbox") {
        change.text = object.text;
      } else if (object.type === "image") {
        change.src = object.src;
      } else if (object.type === "qrcode") {
        change.content = object.content;
      } else {
        // new types ...
      }

      // shared props for every object
      change.fill = object.fill || undefined;
      change.stroke = object.stroke || undefined;

      return change;
    });
  }

  function areChangesValid(changesInString: string): [boolean, ObjectChange[]] {
    try {
      const changes = JSON.parse(changesInString) as ObjectChange[];
      return [true, changes];
    } catch (error) {
      return [false, []];
    }
  }

  async function doPreview(
    template: Template,
    changes: ObjectChange[],
    canvas: fabric.Canvas
  ): Promise<void> {
    textbox.enableTextboxTruncation();

    const copiedTemplateJSON = await utils.overrideTemplateChanges(
      template.data,
      changes
    );
    const waterMarkText = getWaterMarkText(template.width, template.height);
    copiedTemplateJSON.objects.push(waterMarkText);

    canvas.loadFromJSON(copiedTemplateJSON, () => {
      canvas.renderAll();
      textbox.disableTextboxTruncation();

      setImageUrl(
        canvas.toDataURL({
          multiplier: window.devicePixelRatio || 1,
        })
      );
    });
  }

  function onClickToGoBack() {
    navigate(-1);
  }

  function onClickToPreview() {
    const [isValid, changes] = areChangesValid(changesInString);

    if (!canvas) {
      toast.error("canvas is not ready");
      return;
    }

    if (!isValid) {
      toast.error("Changes are not valid");
      return;
    }

    if (!currentTemplate) {
      toast.error("Can't find current template");
      return;
    }

    setIsCreating(true);

    try {
      doPreview(currentTemplate, changes, canvas);
    } catch (error) {
      toast.error((error as Error).message);
    }

    setIsCreating(false);
  }

  async function onClickToRender() {
    const [isValid, changes] = areChangesValid(changesInString);

    if (!isValid) {
      toast.error("Changes are not valid");
      return;
    }

    setIsCreating(true);

    const options = {
      template_id: templateId,
      changes,
    };

    const toastId = toast.loading("Creating image...");

    try {
      const copicake = new Copicake({ apiKey });
      const rendering = await copicake.image.create(options);
      toast.update(toastId, { render: "Retrieving image..." });

      const finalRendering = await copicake.image.getUntilFinished(
        rendering.id
      );

      if (finalRendering.status === "success") {
        setImageUrl(finalRendering.permanent_url);
        toast.update(toastId, {
          render: "Image created!",
          type: "success",
          isLoading: false,
          autoClose: 2000,
        });

        fetch(finalRendering.permanent_url)
          .then((res) => res.blob())
          .then((blob) => {
            const filename = `${currentTemplate?.title || "Image"}.png`;
            saveAs(blob, filename);
          });
      } else {
        throw new Error("failed to render");
      }
    } catch (error) {
      console.error(error);
      toast.update(toastId, {
        render: (error as Error).message,
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
    }

    setIsCreating(false);
  }

  return (
    <div className="h-full">
      <Header />
      <div className="h-full md:h-[calc(100%-73px-81px)]">
        <div className="flex h-full flex-wrap items-center justify-around">
          <div className="flex h-full w-full flex-col overflow-auto p-5 md:w-1/2">
            <div className="mb-8">
              <h1 className="mb-2 flex text-2xl font-bold">
                <span>Render -&nbsp;</span>
                <span className="flex-1 truncate">
                  {currentTemplate?.title}
                </span>
              </h1>
              <p>
                You can render an image by providing the changes in the editor
                below.
              </p>
              <p>
                P.S. You can refer our
                <a
                  href={DOCS_URL}
                  target="_blank"
                  rel="noreferrer"
                  className="mx-1 underline"
                >
                  API Docs
                </a>
                to know the format of changes.
              </p>
            </div>
            <div className="h-full">
              <Editor
                className="h-full"
                defaultLanguage="json"
                defaultValue={changesInString}
                onChange={onEditorChange}
              />
              <div className="hidden">
                <canvas id={canvasId}></canvas>
              </div>
            </div>
          </div>
          <div className="relative flex h-full w-full flex-col items-center justify-center bg-gray-100 md:w-1/2">
            <div className="h-2/3 w-2/3">
              {imageUrl ? (
                <div
                  className="h-full w-full bg-contain bg-center bg-no-repeat"
                  style={{ backgroundImage: `url(${imageUrl})` }}
                ></div>
              ) : (
                <div className="flex h-full w-full items-center justify-center">
                  <Loader className="text-gray-500" />
                </div>
              )}
            </div>
            <div className="absolute top-4 right-5 rounded-md border bg-white py-1 px-3 text-sm font-bold">
              <span>Live Result</span>
              <span className="absolute -top-0.5 -right-0.5 flex h-2 w-2">
                <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-pink-400 opacity-75"></span>
                <span className="relative inline-flex h-2 w-2 rounded-full bg-pink-500"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="border-t p-5">
        <div className="flex justify-between">
          <div className="flex">
            <button
              className="focus:shadow-outline rounded py-2 px-4 font-bold text-black focus:outline-none"
              type="button"
              onClick={onClickToGoBack}
              disabled={isCreating}
            >
              Go back
            </button>
          </div>
          <div className="flex space-x-2">
            <button
              className="focus:shadow-outline rounded bg-indigo-500 py-2 px-4 font-bold text-white hover:bg-indigo-600 focus:outline-none"
              type="button"
              onClick={onClickToPreview}
              disabled={isCreating}
            >
              <span className="mr-1">Preview</span>
              <span className="text-xs">(Free)</span>
            </button>
            <button
              className="focus:shadow-outline rounded bg-indigo-500 py-2 px-4 font-bold text-white hover:bg-indigo-600 focus:outline-none"
              type="button"
              onClick={onClickToRender}
              disabled={isCreating}
            >
              <span className="mr-1">Render</span>
              <span className="text-xs">(1 rendering)</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Preview;
