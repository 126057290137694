import { fabric } from "@utils/fabric";
import { useEffect, useState } from "react";
import { proxyImage } from "@apis/utils";

function ImageSrc() {
  const CANVAS_ID = "canvas-playground";
  const CANVAS_BACKGROUND_COLOR = "#333";
  const SIZE = 700;
  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);

  useEffect(() => {
    clearCanvas();
  }, []);

  function onAddCircleClipPath() {
    canvas?.getObjects("image").forEach((image) => {
      const oldImage = image as fabric.Image;
      const smallerSide = Math.min(oldImage.width, oldImage.height);
      const size = (smallerSide || 0) * 0.5;
      const clipPath = new fabric.Circle({
        radius: size,
        originX: "center",
        originY: "center",
      });

      oldImage.set({ clipPath });
      canvas?.renderAll();
    });
  }

  function onAddTriangleClipPath() {
    canvas?.getObjects("image").forEach((image) => {
      const oldImage = image as fabric.Image;
      const width = oldImage.width || 0;
      const height = oldImage.height || 0;

      const clipPath = new fabric.Triangle({
        left: -width / 2,
        top: -height / 2,
        width,
        height,
      });

      oldImage.set({ clipPath });
      canvas?.renderAll();
    });
  }

  function onAddHorizontalImage() {
    fabric.Image.fromURL(
      "https://s3.ap-northeast-1.amazonaws.com/copicake/images/nx8w7h18k1fi0bijxtt9.jpg",
      (img) => {
        const size = 400;
        img.scaleToWidth(size);
        img.set({
          top: 0.5 * size,
          left: 0.5 * size,
          angle: 45,
          shadow: new fabric.Shadow({
            color: "#ffffff",
            blur: 10,
            offsetX: 10,
            offsetY: 20,
          }),
        });
        canvas?.add(img);
        canvas?.renderAll();
      }
    );
  }

  function onAddVerticalImage() {
    // 1365x2048
    fabric.Image.fromURL(
      "https://s3.ap-northeast-1.amazonaws.com/copicake/images/bntnelh18y7apoo7sw3n.jpeg",
      (img) => {
        const size = 300;
        img.scaleToWidth(size);
        img.set({
          top: 0.5 * size,
          left: 0.5 * size,
        });
        canvas?.add(img);
        canvas?.renderAll();
      }
    );
  }

  function onAddSquareImage() {
    fabric.Image.fromURL(
      "https://s3.ap-northeast-1.amazonaws.com/copicake/images/q7byh88bam5jtg4uah1u.jpg",
      (img) => {
        const size = 300;
        img.scaleToWidth(size);
        img.set({
          top: 0.5 * size,
          left: 0.5 * size,
        });
        canvas?.add(img);
        canvas?.renderAll();
      }
    );
  }

  async function onReplaceHorizontalImage() {
    const newImageSrc = await proxyImage(
      "https://copicake.s3.ap-northeast-1.amazonaws.com/images/c67d54f37d7c-IMG_1997.JPG"
    );
    onCrop(newImageSrc);
  }

  async function onReplaceVerticalImage() {
    const newImageSrc = await proxyImage(
      "https://api.typeform.com/responses/files/d87bb2685074b2b84a1cdc803e6408fbd5255c8c6d032a7dea95de330c658eff/foto.png"
    );

    onCrop(newImageSrc);
  }

  async function onReplaceVertical2Image() {
    const newImageSrc = await proxyImage(
      "https://s3.ap-northeast-1.amazonaws.com/copicake/images/bntnelh18y7apoo7sw3n.jpeg"
    );

    onCrop(newImageSrc);
  }

  async function onReplaceSquareImage() {
    const newImageSrc = await proxyImage(
      "https://s3.ap-northeast-1.amazonaws.com/copicake/images/fctrdp9drg9uqghq8x0e.png"
    );

    onCrop(newImageSrc);
  }

  function onCrop(newImageSrc) {
    canvas?.getObjects("image").forEach((image) => {
      const oldImage = image as fabric.Image;

      const oldWidthBeforeScaling = oldImage.width || 0;
      const oldHeightBeforeScaling = oldImage.height || 0;
      const oldWidthAfterScaling = oldImage.getScaledWidth();
      const oldHeightAfterScaling = oldImage.getScaledHeight();
      const oldCenterPoint = oldImage.getCenterPoint();
      const oldLeft = oldImage.left || 0;
      const oldTop = oldImage.top || 0;
      const oldAngle = oldImage.angle || 0;
      const oldClipPath = oldImage.clipPath;
      const oldShadow = oldImage.shadow;

      console.log("oldWidthBeforeScaling", oldWidthBeforeScaling);
      console.log("oldHeightBeforeScaling", oldHeightBeforeScaling);

      console.log("oldWidthAfterScaling", oldWidthAfterScaling);
      console.log("oldHeightAfterScaling", oldHeightAfterScaling);

      // vertical
      fabric.Image.fromURL(
        newImageSrc,
        (newImage) => {
          const newWidthBeforeScaling = newImage.width || 0;
          const newHeightBeforeScaling = newImage.height || 0;

          const isOldImageVertical =
            oldWidthBeforeScaling < oldHeightBeforeScaling;
          const isOldImageHorizontal =
            oldWidthBeforeScaling > oldHeightBeforeScaling;
          const isNewImageVertical =
            newWidthBeforeScaling < newHeightBeforeScaling;
          const isNewImageHorizontal =
            newWidthBeforeScaling > newHeightBeforeScaling;

          // Scaling the new image to the old image size
          if (isOldImageHorizontal) {
            newImage.scaleToWidth(oldWidthAfterScaling);
          } else if (isOldImageVertical) {
            newImage.scaleToHeight(oldHeightAfterScaling);
          } else {
            if (isNewImageHorizontal) {
              newImage.scaleToHeight(oldHeightAfterScaling);
            } else if (isNewImageVertical) {
              newImage.scaleToWidth(oldWidthAfterScaling);
            } else {
              newImage.scaleToHeight(oldHeightAfterScaling); // scaleToHeight === scaleToWidth
            }
          }

          // need to use our own clipPath to crop image first
          newImage.set({
            clipPath: new fabric.Rect({
              left: oldLeft,
              top: oldTop,
              width: oldWidthAfterScaling,
              height: oldHeightAfterScaling,
              angle: oldAngle,
              absolutePositioned: true,
            }),
          });

          if (oldShadow) {
            newImage.set({ shadow: oldShadow });
          }

          if (oldClipPath) {
            // We only support circle clip path for now
            if (oldClipPath.type === "circle") {
              const oldRadius = oldClipPath.radius || 0;
              const oldScaleX = oldImage.scaleX || 1;
              const oldRadiusAfterScaling = oldRadius * oldScaleX; // scaleX === scaleY
              const newScaleX = newImage.scaleX || 1;

              // x * scaleX = oldRadiusAfterScaling
              // x = oldRadiusAfterScaling / scaleX
              // Crop the image with the right radius
              newImage.set({
                clipPath: new fabric.Circle({
                  radius: oldRadiusAfterScaling / newScaleX,
                  originX: "center",
                  originY: "center",
                }),
              });
            } else {
              newImage.set({
                clipPath: oldClipPath,
              });
            }
          }

          // finally rotate the newImage
          newImage.set({
            angle: oldAngle,
          });

          newImage.setPositionByOrigin(oldCenterPoint, "center", "center");

          const index = canvas?.getObjects().indexOf(oldImage) || 0;
          canvas?.insertAt(newImage, index, true);
          canvas?.renderAll();
        },
        {
          crossOrigin: "anonymous",
        }
      );
    });

    canvas?.renderAll();
  }

  function clearCanvas() {
    const newCanvas = new fabric.Canvas(CANVAS_ID, {
      backgroundColor: CANVAS_BACKGROUND_COLOR,
      width: SIZE,
      height: SIZE,
      controlsAboveOverlay: true,
    });

    setCanvas(newCanvas);
  }

  return (
    <div className="flex h-full w-full bg-gray-200">
      <div>
        <canvas id={CANVAS_ID} className="m-auto" />
      </div>
      <div className="flex flex-col space-y-2 p-4">
        <button
          className="rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700"
          onClick={onAddHorizontalImage}
        >
          Add Image (horizontal)
        </button>
        <button
          className="rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700"
          onClick={onAddVerticalImage}
        >
          Add Image (vertical)
        </button>
        <button
          className="rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700"
          onClick={onAddSquareImage}
        >
          Add Image (Square)
        </button>
        <button
          className="rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700"
          onClick={onAddCircleClipPath}
        >
          Add Circle ClipPath
        </button>
        <button
          className="rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700"
          onClick={onAddTriangleClipPath}
        >
          Add Triangle ClipPath
        </button>
        <button
          className="rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700"
          onClick={onReplaceHorizontalImage}
        >
          Replace Image (horizontal)
        </button>
        <button
          className="rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700"
          onClick={onReplaceVerticalImage}
        >
          Replace Image (vertical)
        </button>
        <button
          className="rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700"
          onClick={onReplaceVertical2Image}
        >
          Replace Image (vertical 2)
        </button>
        <button
          className="rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700"
          onClick={onReplaceSquareImage}
        >
          Replace Image (Square)
        </button>
        <button
          className="rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700"
          onClick={clearCanvas}
        >
          Clear Canvas
        </button>
      </div>
    </div>
  );
}

export default ImageSrc;
