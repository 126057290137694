import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

function WidthHeightProportionalScale() {
  const {
    isWidthHeightProportionalScaled,
    setIsWidthHeightProportionalScaled,
  } = useContext(AppContext);

  return (
    <button
      className={`h-8 rounded px-2 py-2 ${
        isWidthHeightProportionalScaled ? "bg-gray-200" : "bg-slate-100"
      }`}
      onClick={() => {
        setIsWidthHeightProportionalScaled((prevState: boolean) => {
          return !prevState;
        });
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="h-4 w-4"
      >
        {isWidthHeightProportionalScaled && (
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
          />
        )}
        {!isWidthHeightProportionalScaled && (
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.5 10.5V6.75a4.5 4.5 0 119 0v3.75M3.75 21.75h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H3.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
          />
        )}
      </svg>
    </button>
  );
}

export default WidthHeightProportionalScale;
