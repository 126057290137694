import { fabric } from "@utils/fabric";
import { throttle } from "lodash-es";
import BaseHandler from "./BaseHandler";
import CoreHandler from "./CoreHandler";
import {
  DEFAULT_WORKSPACE_BACKGROUND_COLOR,
  DEFAULT_WORKSPACE_MODE,
  WORKSPACE_ID,
} from "@utils/constant";
import { Template } from "@base/type";

type Mode = "view" | "pan";
type PanState = "idle" | "panning";

class WorkspaceHandler extends BaseHandler {
  public coreHandler: CoreHandler;

  public zoomRatio = 0.85;

  public workspace: fabric.Rect | undefined;

  public resizeObserver: ResizeObserver | undefined;

  public mode: Mode = DEFAULT_WORKSPACE_MODE;

  public panState: PanState = "idle";

  public lastClientX: number | undefined;

  public lastClientY: number | undefined;

  public onDomResize: () => void;

  constructor(coreHandler: CoreHandler) {
    super();

    this.coreHandler = coreHandler;
    this.onDomResize = throttle(() => {
      this.zoomAuto();
    }, 100);

    this.panMouseUpHandler = this.panMouseUpHandler.bind(this);
    this.panMouseDownHandler = this.panMouseDownHandler.bind(this);
    this.panMouseMoveHandler = this.panMouseMoveHandler.bind(this);
  }

  initialize(): void {}

  destroy(): void {
    this.unbindDomResizeEvent();
    this.unbindWheelToZoomEvent();
  }

  initializeAfterWorkspaceDomIsReady(template: Template) {
    this.initializeBackground();
    this.initializeWorkspace(template);

    this.bindDomResizeEvent();
    this.bindWheelToZoomEvent();
  }

  initializeBackground() {
    const workspaceDomElement = this.getWorkspaceDomElement();
    if (!workspaceDomElement) {
      return;
    }

    this.coreHandler.canvas.backgroundColor =
      DEFAULT_WORKSPACE_BACKGROUND_COLOR;
    this.coreHandler.canvas.setWidth(workspaceDomElement.offsetWidth);
    this.coreHandler.canvas.setHeight(workspaceDomElement.offsetHeight);
  }

  bindWheelToZoomEvent() {
    this.coreHandler.canvas.on("mouse:wheel", this.onWheelToZoom);
  }

  bindDomResizeEvent() {
    const workspaceDomElement = this.getWorkspaceDomElement();
    if (!this.resizeObserver && workspaceDomElement) {
      this.resizeObserver = new ResizeObserver(this.onDomResize);
      this.resizeObserver.observe(workspaceDomElement);
    }
  }

  unbindWheelToZoomEvent() {
    this.coreHandler.canvas.off("mouse:wheel", this.onWheelToZoom);
  }

  unbindDomResizeEvent() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  initializeWorkspace(template: Template) {
    console.log("initialize workspace");
    const workspace = new fabric.Rect({
      fill: "#ffffff",
      width: template.width,
      height: template.height,
      id: WORKSPACE_ID,
      strokeWidth: 0,
    });

    workspace.set("selectable", false);
    workspace.set("hasControls", false);
    workspace.hoverCursor = "default";

    this.coreHandler.canvas.add(workspace);
    this.coreHandler.canvas.sendToBack(workspace);
    this.coreHandler.canvas.renderAll();

    this.workspace = workspace;
    this.zoomAuto();
  }

  resizeWorkspaceAfterTemplateChange(width: number, height: number) {
    const workspaceObject = this.getWorkspaceObject();
    if (workspaceObject) {
      workspaceObject.set({
        width,
        height,
      });
      this.zoomAuto();
    }
  }

  onWheelToZoom(this: fabric, opt: any) {
    const delta = opt.e.deltaY;
    let zoom = this.getZoom();
    zoom *= 0.999 ** delta;
    if (zoom > 20) zoom = 20;
    if (zoom < 0.01) zoom = 0.01;
    this.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
    opt.e.preventDefault();
    opt.e.stopPropagation();
  }

  getWorkspaceDomElement(): HTMLDivElement | null {
    return document.getElementById(`#${WORKSPACE_ID}`) as HTMLDivElement;
  }

  getWorkspaceObject(): fabric.Rect {
    return this.coreHandler.canvas
      .getObjects()
      .find((item) => item.id === WORKSPACE_ID);
  }

  getScaleToFit(): number {
    const workspaceDomElement = this.getWorkspaceDomElement();
    const workspaceObject = this.getWorkspaceObject();
    if (workspaceDomElement && workspaceObject) {
      return fabric.util.findScaleToFit(workspaceObject, {
        width: workspaceDomElement.offsetWidth,
        height: workspaceDomElement.offsetHeight,
      });
    }
    return 1;
  }

  zoomAuto() {
    const scale = this.getScaleToFit();
    this.setZoomAuto(scale * this.zoomRatio);
  }

  zoomOne() {
    this.setZoomAuto(1 * this.zoomRatio);
    this.coreHandler.canvas.requestRenderAll();
  }

  zoomIn() {
    let zoomRatio = this.coreHandler.canvas.getZoom();
    zoomRatio += 0.05;
    const center = this.coreHandler.canvas.getCenter();
    this.coreHandler.canvas.zoomToPoint(
      new fabric.Point(center.left, center.top),
      zoomRatio
    );
  }

  zoomOut() {
    let zoomRatio = this.coreHandler.canvas.getZoom();
    zoomRatio -= 0.05;
    const center = this.coreHandler.canvas.getCenter();
    this.coreHandler.canvas.zoomToPoint(
      new fabric.Point(center.left, center.top),
      zoomRatio < 0 ? 0.01 : zoomRatio
    );
  }

  setZoomAuto(scale: number) {
    const { canvas } = this.coreHandler;
    const workspaceDomElement = this.getWorkspaceDomElement();
    if (!workspaceDomElement) {
      return;
    }

    const width = workspaceDomElement.offsetWidth;
    const height = workspaceDomElement.offsetHeight;

    canvas.setWidth(width);
    canvas.setHeight(height);

    const center = canvas.getCenter();
    canvas.setViewportTransform(fabric.iMatrix.concat());
    canvas.zoomToPoint(new fabric.Point(center.left, center.top), scale);

    if (!this.workspace) {
      return;
    }

    this.setCenterFromObject(this.workspace);

    this.workspace.clone((cloned: fabric.Rect) => {
      canvas.clipPath = cloned;
      canvas.requestRenderAll();
    });
  }

  setCenterFromObject(object: fabric.Object) {
    const { canvas } = this.coreHandler;
    const objectCenter = object.getCenterPoint();

    const viewportTransform = canvas.viewportTransform;
    if (
      canvas.width === undefined ||
      canvas.height === undefined ||
      !viewportTransform
    ) {
      return;
    }

    viewportTransform[4] =
      canvas.width / 2 - objectCenter.x * viewportTransform[0];
    viewportTransform[5] =
      canvas.height / 2 - objectCenter.y * viewportTransform[3];
    canvas.setViewportTransform(viewportTransform);
    canvas.renderAll();
  }

  panMouseUpHandler(this: WorkspaceHandler, e: any) {
    this.panState = "idle";
  }

  panMouseDownHandler(this: WorkspaceHandler, e: any) {
    this.panState = "panning";
    this.lastClientX = e.e.clientX;
    this.lastClientY = e.e.clientY;
  }

  panMouseMoveHandler(this: WorkspaceHandler, e: any) {
    const { canvas } = this.coreHandler;
    if (this.panState === "panning" && e && e.e) {
      // let delta = new fabric.Point(e.e.movementX, e.e.movementY); // No Safari support for movementX and movementY
      // For cross-browser compatibility, I had to manually keep track of the delta

      // Calculate deltas
      let deltaX = 0;
      let deltaY = 0;
      if (this.lastClientX) {
        deltaX = e.e.clientX - this.lastClientX;
      }
      if (this.lastClientY) {
        deltaY = e.e.clientY - this.lastClientY;
      }
      // Update the last X and Y values
      this.lastClientX = e.e.clientX;
      this.lastClientY = e.e.clientY;

      let delta = new fabric.Point(deltaX, deltaY);
      canvas.relativePan(delta);
    }
  }

  setMode(mode: Mode) {
    if (mode === this.mode) {
      return;
    }

    const { canvas } = this.coreHandler;
    this.mode = mode;

    if (this.mode === "pan") {
      canvas.discardActiveObject();
      canvas.defaultCursor = "move";
      canvas.forEachObject(function (object: fabric.Object) {
        object.prevEvented = object.evented;
        object.prevSelectable = object.selectable;
        object.evented = false;
        object.selectable = false;
      });

      canvas.on("mouse:up", this.panMouseUpHandler);
      canvas.on("mouse:down", this.panMouseDownHandler);
      canvas.on("mouse:move", this.panMouseMoveHandler);
    } else {
      canvas.forEachObject(function (object) {
        object.evented =
          object.prevEvented !== undefined
            ? object.prevEvented
            : object.evented;
        object.selectable =
          object.prevSelectable !== undefined
            ? object.prevSelectable
            : object.selectable;
      });
      canvas.defaultCursor = "default";
      canvas.off("mouse:up", this.panMouseUpHandler);
      canvas.off("mouse:down", this.panMouseDownHandler);
      canvas.off("mouse:move", this.panMouseMoveHandler);
    }

    this.emit("mode:change", this.mode);
  }
}

export default WorkspaceHandler;
