import React, { useContext, useEffect, useState } from "react";
import { fabric } from "@utils/fabric";
import { AppContext } from "../../context/AppContext";
import NumberInput from "./shared/NumberInput";

function getLeft(selectedObject: fabric.Object | null) {
  const boundingRect = selectedObject?.getBoundingRect();
  return boundingRect?.left || 0;
}

function Left() {
  const { canvas, selectedObject, coreHandler } = useContext(AppContext);
  const [left, setLeft] = useState(getLeft(selectedObject));

  // triggered by clicking layers
  useEffect(() => {
    const left = getLeft(selectedObject);
    setLeft(left);
  }, [selectedObject]);

  // triggered by fabric controls
  useEffect(() => {
    function onModified(event: any) {
      const left = getLeft(selectedObject);
      setLeft(left);
    }

    coreHandler?.eventHandler.on("object:modified", onModified);
    return () => {
      coreHandler?.eventHandler.off("object:modified", onModified);
    };
  }, [coreHandler, selectedObject]);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (selectedObject) {
      const value = parseInt(event.target.value, 10);
      const boundingRect = selectedObject.getBoundingRect();
      const objectCenter = selectedObject.getCenterPoint();

      selectedObject?.setPositionByOrigin(
        new fabric.Point(value + boundingRect.width / 2, objectCenter.y),
        "center",
        "center"
      );

      canvas?.renderAll();
      setLeft(value);
    }
  }

  return (
    <NumberInput
      onChange={onChange}
      value={left}
      className="h-8 w-full rounded"
    />
  );
}

export default Left;
