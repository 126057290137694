import React, { useContext, useEffect, useState } from "react";
import { fabric } from "@utils/fabric";
import { AppContext } from "../../context/AppContext";
import NumberInput from "./shared/NumberInput";

function MaxLines() {
  const { canvas, selectedObject } = useContext(AppContext);
  const [maxLines, setMaxLines] = useState(
    (selectedObject as fabric.Textbox).get("maxLines") ||
      fabric.Textbox.prototype.maxLines
  );

  // triggered by clicking layers
  useEffect(() => {
    const value =
      (selectedObject as fabric.Textbox).get("maxLines") ||
      fabric.Textbox.prototype.maxLines;

    setMaxLines(value);
  }, [selectedObject]);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = Math.max(0, parseInt(event.target.value, 10));
    setMaxLines(value);
    (selectedObject as fabric.Textbox).set({ maxLines: value });
    canvas?.renderAll();
  }

  return (
    <NumberInput
      onChange={onChange}
      value={maxLines}
      min={0}
      step={1}
      className="h-8 w-full rounded"
    />
  );
}

export default MaxLines;
